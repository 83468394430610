@import "src/variables.scss";

.fillHeight {
  min-height: 100%;

  @include mobile-only {
    min-height: 100vh;
  }
}

.horizontalLayout {
  display: flex;
  flex-direction: row;
}

.containerOverride {
  margin: 0;
  padding-top: 20px;
  flex: 1 1 auto;
}

.pageContent {
  @media (min-width: 500px) {
    padding-bottom: 75px;
  }
  @media (max-width: 500px) {
    padding-bottom: 118px;
  }
}
