$breakpoint-tablet-width: 767px;
$breakpoint-desktop-width: 1023px;

$theme-background: rgba(
  $color: #ffffff,
  $alpha: 0.8
);
$theme-text-color: #000000;

$theme-sidebar-width: 230px;

$theme-sidebar-background: #333333;
$theme-sidebar-text-color: #ffffff;
$theme-sidebar-link-hover-background: darken($theme-sidebar-background, 5%);
$theme-sidebar-divider-color: $theme-sidebar-text-color;

$theme-sidebar-active-background: #eaeaea;
$theme-sidebar-active-text-color: $theme-text-color;
$theme-sidebar-active-link-hover-background: darken(
  $theme-sidebar-active-background,
  2%
);

$theme-sidebar-subpage-background: darken(
  $theme-sidebar-active-background,
  10%
);
$theme-sidebar-subpage-text-color: $theme-text-color;
$theme-sidebar-subpage-link-hover-background: darken(
  $theme-sidebar-subpage-background,
  5%
);

$theme-header-height: 54px;

$theme-footer-height: 54px;
$theme-footer-mobile-buttons-height: 54px;
$theme-footer-mobile-progress-height: 44px;
$theme-footer-mobile-height: #{$theme-footer-mobile-buttons-height +
  $theme-footer-mobile-progress-height};

// By default all CSS applies to mobile, so it only needs an exclusive mixin
@mixin mobile-only {
  @media (max-width: #{$breakpoint-tablet-width}) {
    @content;
  }
}

// Use this to include the content in everything tablet and larger
@mixin tablet {
  @media (min-width: #{$breakpoint-tablet-width}) {
    @content;
  }
}

// Use this to include content exclusively in tablet
@mixin tablet-only {
  @media (min-width: #{$breakpoint-tablet-width}) and (max-width: #{$breakpoint-desktop-width - 1px}) {
    @content;
  }
}

// Since there's nothing larger than desktop, it can only have an exclusive mixin
@mixin desktop-only {
  @media (min-width: #{$breakpoint-desktop-width}) {
    @content;
  }
}
