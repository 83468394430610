/**
Override any default bootstrap variables here
*/

$primary: #0081c6;
$secondary: #9ec087;

@import "~bootstrap/scss/bootstrap.scss";
@import "~react-perfect-scrollbar/dist/css/styles.css";
@import "src/variables.scss";
@import "~video-react/styles/scss/video-react";
@import url("https://use.fontawesome.com/releases/v5.13.0/css/all.css");

@media print {
  .noPrint {
    // give an element the noPrint class to remove it from the printable version
    display: none !important;
  }

  #nav-bar,
  #nav-bar-footer-buttons-small-return,
  #nav-bar-footer,
  #nav-bar-footer-buttons-small,
  #sidebar {
    // hides generic layout elements
    display: none;
  }

  #nav-from-summary {
    // hides Edit buttons on summary page
    display: none;
  }

  html,
  body {
    // removes empty second page from print
    height: auto !important;
  }
}

.ps__rail-y {
  opacity: 0.6;
}

.btn-light {
  @include button-variant(
    $light,
    $light,
    $active-background: darken(#9ec087, 0%)
  );
}

html,
body,
#root {
  @include mobile-only {
    min-height: 100%;
  }
  @include tablet {
    height: 100%;
  }
  background-image: linear-gradient(to right, #9ec086, #78c6e6);
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  color: $theme-text-color;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#landing-box-med {
  background-color: $theme-background;
  padding-right: 80px;
  padding-left: 80px;
  padding-top: 20px;
  padding-bottom: 20px;
  justify-content: center;
  align-content: stretch;
  position: relative;
  border-radius: 6px;
  text-align: left;
  max-height: 80%;
  max-width: 60%;
  margin: 0 auto;
}

#landing-box-big {
  background-color: $theme-background;
  padding-right: 80px;
  padding-left: 80px;
  padding-top: 20px;
  padding-bottom: 20px;
  justify-content: center;
  align-content: stretch;
  position: relative;
  border-radius: 6px;
  text-align: left;
  max-height: 80%;
  max-width: 44%;
  margin: 0 auto;
}

#landing-box {
  background-color: $theme-background;
  padding-right: 32px;
  padding-left: 32px;
  padding-top: 5px;
  padding-bottom: 5px;
  justify-content: center;
  align-content: stretch;
  position: relative;
  border-radius: 6px;
  text-align: left;
  max-height: 80%;
  max-width: 86%;
  margin: 0 auto;
}

#main-box {
  background-color: $theme-background;
  padding: 20px 40px;
  justify-content: center;
  align-content: stretch;
  position: relative;
  border-radius: 6px;
  text-align: left;
  min-height: 100%;

  @include tablet {
    padding: 80px 120px 20px;
  }
}

#nav-bar,
#nav-bar-footer {
  background-color: $theme-background;
}

#nav-bar {
  height: $theme-header-height;
  box-shadow: 0px 2px 6px rgba($color: black, $alpha: 0.1);
}

#nav-bar {
  .dropdown-toggle::after {
    content: none;
  }
}

#nav-bar-footer {
  height: $theme-footer-height;
  box-shadow: 0px -2px 6px rgba($color: black, $alpha: 0.1);
  justify-content: space-between;
  align-items: center;
}

#logo-subtext {
  color: rgba($color: #0081c6, $alpha: 1);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 18px;
}

#nav-bar-footer .dropdown-menu {
  max-height: 40vh;
  overflow: hidden;
  overflow-y: auto;
}

#nav-bar-footer-buttons-small {
  height: $theme-footer-mobile-buttons-height;
  background-color: $theme-background;
  box-shadow: 0px -2px 6px rgba($color: black, $alpha: 0.1);
  /*margin-bottom: 43.5px;*/
  justify-content: space-between;
  align-items: center;
}

#nav-bar-footer-buttons-small-return {
  height: $theme-footer-mobile-buttons-height;
  background-color: $theme-background;
  box-shadow: 0px -2px 6px rgba($color: black, $alpha: 0.1);
  margin-top: 20px;
  /*margin-bottom: 43.5px;*/
  justify-content: space-around;
  align-items: center;
}

#nav-bar-footer-buttons-small .dropdown-menu {
  max-height: 40vh;
  overflow: hidden;
  overflow-y: auto;
}

#nav-bar-footer-prog {
  height: $theme-footer-mobile-progress-height;
  background-color: $theme-background;
  margin: 0px;
  justify-content: space-between;
  align-items: center;
}

#flip-card {
  background-color: $theme-background;
  padding-right: 40px;
  padding-left: 40px;
  padding-top: 20px;
  padding-bottom: 20px;
  justify-content: center;
  align-content: stretch;
  position: relative;
  border-radius: 6px;
  text-align: left;
  min-height: 100%;
}

#nav-from-summary {
  float: right;
}

#flip-toggler {
  display: inline-block;
  float: right;
}

#card-title {
  display: inline-block;
}

.card-header-text {
  .dropdown {
    float: right;
  }
}

#summary-title,
#summary-code,
#summary-button {
  display: inline-block;
}

#summary-info {
  float: left;
}

#summary-code-phone,
#summary-button-phone {
  display: inline-block;
}

#summary-page-title-phone,
#email-button-phone {
  display: inline-block;
  align-items: center;
}

#email-button,
#summary-button {
  display: inline-block;
  float: right;
}

#summary-ul {
  padding-left: 1.2em;
  margin: 0px;
}

/*#summary-subheader {
  text-decoration: underline;
}*/

#activity-1-button {
  text-align: left;
}

#option-button {
  text-align: left;
}

#medUp-option-button {
  text-align: center;
}

#medUp-option-button-activity1 {
  text-align: center;
  display: block;
  width: 70%;
  margin: 0 auto;
}

#medUp-option-button-activity1-phone {
  text-align: center;
  display: block;
  width: 70%;
}

#activity-1-textbox {
  float: right;
}

#progSymbols {
  text-align: center;
}

#progress-text-dots {
  justify-content: space-between;
  align-items: center;
}

#progress-checkboxes-row {
  text-align: center;
  display: inline-block;
}

#RL-accordion-text {
  text-align: left;
}

.video-div {
  display: flex;
  justify-content: center;
}

.vimeo-iframe {
  border-style: none;
  background-color: rgba($color: #ffffff, $alpha: 0);
}

#request-new-code-button-div {
  display: flex;
  justify-content: center;
}
/*tr td:last-child {
  width: 1%;
  white-space: nowrap;
}*/

#dropdown-logout {
  text-align: center;
}

#navbar-code-logout {
  text-align: center;
  vertical-align: middle;
}

#back-button {
  margin-left: 230px;
  width: 74px;
}

#next-button {
  width: 74px;
}

#back-button-phone {
  width: 74px;
}

#header-code-logout-nav {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
}

#question-container {
  background-color: rgba(209, 209, 209, 0.38);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 5px;
  display: inline-block;
  justify-content: center;
  width: 100%;
  border-radius: 6px;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#return-button {
  width: 250px;
  display: inline-block;
}

#return-button-nav {
  display: inline-block;
  text-align: center;
}
#nav-bar-footer-return {
  text-align: center;
  background-color: $theme-background;
  height: $theme-footer-height;
  box-shadow: 0px -2px 6px rgba($color: black, $alpha: 0.1);
  justify-content: space-between;
  align-items: center;
}

#landing-text {
  text-align: center;
}

#about-tech-req {
  text-decoration: underline;
}

#key-image {
  float: left;
}

#title-div {
  text-align: center;
}

#question-container {
  h6 {
    font-size: 110%;
  }
}

#landing-text {
  color: #409596;
}

#div.justify-content-md-center.pt-4.row,
div.col-md-auto {
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 0px;
  margin-right: 0px;
  width: 100%;
}

.Collapsible {
  color: black;
  margin-bottom: 10px;
}

.Collapsible__contentInner {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  background: white;
  border-radius: 0px 0px 1px 1px;
}

.Collapsible__trigger {
  display: block;
  position: relative;
  padding: 10px;
  background: #f7f7f7;
  border-radius: 1px;
  color: black;
}

.Collapsible__trigger:after {
  font-family: "Font Awesome 5 Free";
  content: "\f107";
  font-weight: 900;
  text-rendering: optimizeLegibility;
  position: relative;
  float: right;
  display: inline-block;
  transition: transform 400ms;
}

.Collapsible__trigger.is-open:after {
  transform: rotateZ(180deg);
}

#summary-print-logo {
  display: none;
}

@media print {
  #summary-print-logo {
    display: inline-block;
  }
}
